import React, {Component} from 'react';
import Chart from "react-google-charts";
import Waiting from "../../common/containers/Waiting";
import Ajax from "../../common/ajax";
import PrivatePage from "../../common/containers/PrivatePage";
import Content from "../../common/containers/Content";
import Row from "../../common/containers/Row";
import Col from "../../common/containers/Col";
import Config from "../../config";
import LocaleUtils from "../../common/LocaleUtils";

class UsersReportPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: {},
            usersPerMonth: null,
            accumulatedUsers: null,
            usersPerUF: null,
        };
    }

    componentDidMount() {
        this.fetchUsers();
        this.fetchUsersPerMonth();
        this.fetchAccumulatedUsers();
        this.fetchUsersPerBroaderArea();
    }

    fetchUsers() {
        Ajax.get(`${Config.djangoHost}r/users`).done(response => {
            this.setState(state => ({
                ...state,
                users: response,
            }));
        });
    }

    fetchUsersPerMonth() {
        Ajax.get(`${Config.djangoHost}r/users-per-month`).done(response => {
            this.setState(state => ({
                ...state,
                usersPerMonth: response,
            }));
        });
    }

    fetchAccumulatedUsers() {
        Ajax.get(`${Config.djangoHost}r/accumulated-users`).done(response => {
            this.setState(state => ({
                ...state,
                accumulatedUsers: response,
            }));
        });
    }

    fetchUsersPerBroaderArea() {
        Ajax.get(`${Config.djangoHost}r/users-per-uf`).done(response => {
            this.setState(state => ({
                ...state,
                usersPerUF: response,
            }));
        });
    }

    render() {
        return (
            <PrivatePage title="Users Report">
                <Content>
                    <Row>
                        <Col md={6}>
                            <table className="table">
                                <tbody>
                                <tr>
                                    <td>First registration date</td>
                                    <td>{LocaleUtils.calendar(this.state.users.first_registration_date)}</td>
                                </tr>
                                <tr>
                                    <td>Last registration date</td>
                                    <td>{LocaleUtils.calendar(this.state.users.last_registration_date)}</td>
                                </tr>
                                <tr>
                                    <td>Number of days running</td>
                                    <td>{this.state.users.number_of_days}</td>
                                </tr>
                                <tr>
                                    <td>Number of users</td>
                                    <td>{this.state.users.number_of_users}</td>
                                </tr>
                                <tr>
                                    <td>Number of new users today</td>
                                    <td>{this.state.users.number_of_new_users_today}</td>
                                </tr>
                                <tr>
                                    <td>Avg. number of new users per day</td>
                                    <td>{this.state.users.avg_new_users}</td>
                                </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12}>
                            <Waiting isProcessing={!this.state.accumulatedUsers}>
                                {this.state.accumulatedUsers && (
                                    <Chart
                                        // width={500}
                                        height={500}
                                        chartType="AreaChart"
                                        loader={<div>Loading Chart</div>}
                                        data={this.state.accumulatedUsers}
                                        options={{
                                            title: "Accumulated users",
                                            hAxis: {
                                                gridlines: { count: 10 },
                                                slantedText:true,
                                                slantedTextAngle:90 // here you can even use 180
                                            },
                                            isStacked: true,
                                            legend: { position: "top", maxLines: 3 },
                                        }}
                                        legendToggle
                                    />
                                )}
                            </Waiting>
                        </Col>
                    </Row>

                    <br/>
                    <Row>
                        <Col md={12}>
                            <Waiting isProcessing={!this.state.usersPerMonth}>
                                {this.state.usersPerMonth && (
                                    <Chart
                                        // width={500}
                                        height={500}
                                        chartType="ColumnChart"
                                        loader={<div>Loading Chart</div>}
                                        data={this.state.usersPerMonth}
                                        options={{
                                            title: "Users per month",
                                            hAxis: {
                                                gridlines: { count: 10 },
                                                slantedText:true,
                                                slantedTextAngle:90 // here you can even use 180
                                            },
                                            isStacked: true,
                                            legend: { position: "top", maxLines: 3 },
                                        }}
                                        legendToggle
                                    />
                                )}
                            </Waiting>
                        </Col>
                    </Row>

                    <br/>
                    <Row>
                        <Col md={12}>
                            <Waiting isProcessing={!this.state.usersPerUF}>
                                {this.state.usersPerUF && (
                                    <Chart
                                        // width={500}
                                        height={500}
                                        chartType="ColumnChart"
                                        loader={<div>Loading Chart</div>}
                                        data={this.state.usersPerUF}
                                        options={{
                                            title: "Users per UF",
                                            hAxis: {
                                                gridlines: { count: 10 },
                                                slantedText:true,
                                                slantedTextAngle:90 // here you can even use 180
                                            },
                                            isStacked: true,
                                            legend: { position: "top", maxLines: 3 },
                                        }}
                                        legendToggle
                                    />
                                )}
                            </Waiting>
                        </Col>
                    </Row>

                    <br/>
                    <Row>
                        <Col md={12}>
                            <Chart
                                height={500}
                                chartType="GeoChart"
                                data={(this.state.usersPerUF || []).map(i => [`BR-${i[0]}`, i[1]])}
                                options={{
                                    region: 'BR',
                                    resolution: 'provinces',
                                    title: 'Users per UF',
                                    displayMode: 'auto',
                                }}
                            />
                        </Col>
                    </Row>
                </Content>
            </PrivatePage>
        );
    }
}

export default UsersReportPage;